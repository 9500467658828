import React from 'react'

import Layout from '../../components/Layout'

import BlogRoll from '../../components/BlogRolltwo'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
            <div className="fixed">
              <BlogRoll />
            </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
